import { equalsIgnoreCase } from '@dte/otw/utils/core/src/strings/equalsIgnoreCase';
import { lowerCaseValue } from '@dte/otw/utils/core/src/strings/lowerCaseValue';
import { safeParseURL } from './safeParseURL';

export function getHostname(url: string | URL): string {
	const urlString = safeParseURL(url);
	if (!urlString) {
		return undefined;
	}

	const urlObject = new URL(urlString);
	return lowerCaseValue(urlObject?.hostname);
}

export function matchesHostname(url: string | URL, hostname: string, matchFull?: boolean): boolean {
	const urlHostname = getHostname(url);
	if (!urlHostname) {
		return false;
	}

	// for match full, we want to compare the full hostname
	if (matchFull) {
		return equalsIgnoreCase(urlHostname, hostname);
	}

	// Return a match as long as the hostname ends with the provided hostname
	const lowercaseHostname = lowerCaseValue(hostname);
	return urlHostname.endsWith(lowercaseHostname);
}
