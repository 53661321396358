import { isEmpty, isNotEmpty } from '../objects/isEmpty';

export function includesAny<T>(mustBeIncluded: T[], values: T[]): boolean {
	// If the list of what must be included is empty, consider that to be included
	if (!mustBeIncluded?.length) {
		return true;
	}
	return mustBeIncluded.some((value) => values.includes(value));
}

export function includesOnly<T>(mustBeIncluded: T[], values: T[]): boolean {
	// If the list of what must be included is empty, consider that to be included
	if (isEmpty(mustBeIncluded)) {
		return isNotEmpty(values);
	}

	if (isEmpty(values)) {
		return false;
	}

	return values.every((value) => mustBeIncluded.includes(value));
}
