import { CompareOrder } from '@dte/otw/utils/core/src/objects/compare/CompareOrder';
import { compareGreater } from '@dte/otw/utils/core/src/objects/compare/compareGreater';
import { safeParseDate } from '../parse/date';

export function compareLatest(
	a: string | number | Date,
	b: string | number | Date,
	// For sorting, put undefined at the start of the list by default
	handleUndefined: CompareOrder.Before | CompareOrder.After = CompareOrder.Before,
): CompareOrder {
	const timeA = safeParseDate(a)?.getTime();
	const timeB = safeParseDate(b)?.getTime();

	return compareGreater(timeA, timeB, handleUndefined);
}
