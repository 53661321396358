import { CompareOrder } from '@dte/otw/utils/core/src/objects/compare/CompareOrder';
import { compareLatest } from './compareLatest';

/**
 * Return true if dateA is after dateB
 *
 * @param comesAfter
 * @param compareAgainst
 * @returns
 */
export function isAfter(
	comesAfter: string | number | Date,
	compareAgainst: string | number | Date,
	handleUndefined: CompareOrder.Before | CompareOrder.After = CompareOrder.Before,
): boolean {
	return compareLatest(comesAfter, compareAgainst, handleUndefined) === CompareOrder.Before;
}
