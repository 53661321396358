import { includesAny } from '@dte/otw/utils/core/src/lists/includesAny';

export enum Roles {
	Admin = 'otw.admin',
	ReadSummary = 'summary.read',
	ReadHistory = 'history.read',

	ReadOrders = 'orders.read',
	WriteOrders = 'orders.write',

	WriteConfig = 'config.write',
	WriteModes = 'modes.write',
	WriteMessages = 'messages.write',
	WriteCounts = 'counts.write',
	WriteEstimates = 'estimates.write',
}

// Features within the app
export enum AppAction {
	Summary = 'summary',
	Timeline = 'timeline',
	SearchOrders = 'orders',

	ReportProblem = 'reportProblem',
	AddCallComment = 'addCallComment',

	SwitchMode = 'switchMode',
	EditModes = 'editModes',
	EditCounts = 'editCounts',
	EditSettings = 'editSettings',

	// Any config action
	ConfigMenu = 'config',

	Debug = 'debug',

	EditEstimateWindows = 'EditEstimateWindows',
	EditDefaultEstimateWindows = 'EditDefaultEstimateWindows',
}

// Roles that are allowed to access each feature
const AllowedRoles: Partial<Record<AppAction, string[]>> = {};
AllowedRoles[AppAction.Summary] = [Roles.ReadSummary];
AllowedRoles[AppAction.Timeline] = [Roles.ReadHistory];

AllowedRoles[AppAction.ReportProblem] = [Roles.WriteOrders];
AllowedRoles[AppAction.SearchOrders] = [Roles.WriteOrders, Roles.ReadOrders];
AllowedRoles[AppAction.AddCallComment] = [Roles.WriteOrders];

AllowedRoles[AppAction.SwitchMode] = [Roles.WriteConfig, Roles.WriteModes];
AllowedRoles[AppAction.EditModes] = [Roles.WriteConfig, Roles.WriteMessages];
AllowedRoles[AppAction.EditCounts] = [Roles.WriteCounts];
AllowedRoles[AppAction.EditSettings] = [];

AllowedRoles[AppAction.EditEstimateWindows] = [Roles.WriteConfig, Roles.WriteEstimates];
AllowedRoles[AppAction.EditDefaultEstimateWindows] = [];

// Can access config if you can do any of the other editing type activities
AllowedRoles[AppAction.ConfigMenu] = [
	...AllowedRoles[AppAction.SwitchMode],
	...AllowedRoles[AppAction.EditModes],
	...AllowedRoles[AppAction.EditCounts],
	...AllowedRoles[AppAction.EditSettings],
];

AllowedRoles[AppAction.Debug] = ['otw.admin', 'history.read'];

export function isAllowed(action: AppAction, userRoles: string[]): boolean {
	// Roles not defined yet
	if (userRoles === undefined) {
		return undefined;
	}

	// Assume true if no action is passed in
	if (!action) {
		return true;
	}

	// Admin role is always allowed
	if (userRoles?.includes(Roles.Admin)) {
		return true;
	}

	return includesAny(AllowedRoles[action], userRoles);
}
