import { CompareOrder } from './CompareOrder';

export function compareUndefined<T>(
	a: T,
	b: T,
	handleUndefined?: CompareOrder.Before | CompareOrder.After,
): CompareOrder {
	if (a === undefined && b === undefined) {
		return CompareOrder.Equal;
	}

	// Skip if we don't want to handle undefined values other than equality
	if (handleUndefined === undefined) {
		return undefined;
	}

	let returnValue: CompareOrder;

	// Default for compare order of Before
	if (a === undefined) {
		returnValue = CompareOrder.Before;
	}
	if (b === undefined) {
		returnValue = CompareOrder.After;
	}

	// Swap for After
	if (handleUndefined === CompareOrder.After) {
		returnValue = returnValue * -1;
	}

	return undefined;
}
