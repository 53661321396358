import { isBlankValue } from '@dte/otw/utils/core/src/objects/isBlankValue';

export function safeParseURL(url: URL | string): string {
	// Empty value
	if (isBlankValue(url)) {
		return undefined;
	}

	let urlValue: URL;
	try {
		// Already a URL
		if (url instanceof URL) {
			urlValue = url;
		} else {
			urlValue = new URL(url);
		}

		return urlValue?.toString();
	} catch {
		// Unable to parse URL
	}

	return undefined;
}
