import { CompareOrder } from './CompareOrder';
import { compareUndefined } from './compareUndefined';

export function compareGreater<T>(
	a: T,
	b: T,
	handleUndefined?: CompareOrder.Before | CompareOrder.After,
): CompareOrder {
	const undefinedComparison = compareUndefined(a, b, handleUndefined);
	if (undefinedComparison !== undefined) {
		return undefinedComparison;
	}

	if (a > b) {
		return CompareOrder.Before;
	}

	if (b > a) {
		return CompareOrder.After;
	}

	return CompareOrder.Equal;
}
