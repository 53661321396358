import { filterEmpty } from '@dte/otw/utils/core/src/lists/filterEmpty';
import { safeParseInt } from '@dte/otw/utils/core/src/numbers/safeParseInt';
import { isBlankValue } from '@dte/otw/utils/core/src/objects/isBlankValue';
import { normalizeValue } from '@dte/otw/utils/core/src/strings/normalizeValue';
import { differenceInYears, isValid, parseISO } from 'date-fns';

function parseNumericDate(date: number): Date {
	try {
		// If the date is in milliseconds, adding 1000 to it will result in a date will generally result in a date that's far in the future
		const futureDate = new Date(date * 1000);
		const yearsAhead = differenceInYears(futureDate, new Date());
		if (yearsAhead > 500) {
			return new Date(date);
		}

		// TODO: this approach has gaps for dates in the early 1970's

		// Assume the date was in seconds
		return futureDate;
	} catch {
		// Unable to parse date
	}

	// If the number doesn't match expected lengths, return undefined
	return undefined;
}

function parseStringDate(date: string): Date {
	const normalized = normalizeValue(date);

	try {
		const parsed = parseISO(normalized);
		if (isValid(parsed)) {
			return parsed;
		}
	} catch {
		// Unable to parse date
	}

	return undefined;
}

export function safeParseDate(date: string | number | Date): Date {
	if (isBlankValue(date)) {
		return undefined;
	}

	// Date is already a date
	if (date instanceof Date) {
		return date;
	}

	// Date is a string
	if (typeof date === 'string') {
		const dateValue = parseStringDate(date);
		if (dateValue) {
			return dateValue;
		}
	}

	// If we weren't able to parse it as a string, try seeing if the value is numeric
	const numericValue = safeParseInt(date);
	if (numericValue) {
		return parseNumericDate(numericValue);
	}

	return undefined;
}

export function safeParseDates(dates: Array<string | number | Date>): Date[] {
	let dateValues = dates?.map(safeParseDate).filter((date) => date);
	dateValues = filterEmpty(dateValues);

	return dateValues;
}
